body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  object-fit: cover;
  object-position: center;
  position: absolute;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  pointer-events: none;
}

.dot-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  background: radial-gradient(rgb(0, 0, 0) 2px, transparent 2px) 0% 0% / 4px 4px;
  background-size: 4px 4px;
  opacity: 0.5;
}

.text-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.text-container {
  max-width: calc(75vh * (4 / 3));
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  color: white;
}

.text-background {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 5px;
  display: table;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.mt-4 {
  margin-top: 1rem;
}

.btn-primary {
  background-color: rgb(81 81 81 / 60%);
  border: 2px solid #202020;
  color: white;
  padding: 0.6rem 1.4rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
  margin: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.btn-primary:hover {
  background-color: rgba(0, 123, 255, 0.8);
}
